var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"app-sidebar"},[_c('nav',{staticClass:"sidebar sidebar-offcanvas",attrs:{"id":"sidebar"}},[_c('ul',{staticClass:"nav"},[_c('li',{staticClass:"nav-item nav-profile"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#/my-profile"}},[_c('div',{staticClass:"profile-image"},[_c('img',{staticClass:"img-xs rounded-circle",attrs:{"src":_vm.getUser.picture,"alt":"profile image"}}),_c('div',{staticClass:"dot-indicator bg-success"})]),_c('div',{staticClass:"text-wrapper"},[_c('div',{staticClass:"profile-name"},[_vm._v(_vm._s(_vm.getUser.firstname))]),_c('div',{staticClass:"designation"},[_vm._v(_vm._s(_vm.getUser.lastname))])])])]),_c('li',{staticClass:"nav-item nav-category"},[_vm._v("Main Menu")]),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/dashboard' }}},[_c('i',{staticClass:"menu-icon typcn typcn-home-outline typcn-3x"}),_c('span',{staticClass:"menu-title"},[_vm._v("Dashboard")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'eagleeye' }}},[_c('i',{staticClass:"menu-icon typcn typcn-eye"}),_c('span',{staticClass:"menu-title"},[_vm._v("Eagle Eyes")])])],1),_c('li',{staticClass:"nav-item"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('ui-basic'),expression:"'ui-basic'"}],staticClass:"nav-link"},[_c('i',{staticClass:"menu-icon typcn typcn-coffee"}),_c('span',{staticClass:"menu-title"},[_vm._v("Manage Vehicles")]),_c('i',{staticClass:"menu-arrow"})]),_c('b-collapse',{attrs:{"id":"ui-basic"}},[_c('ul',{staticClass:"nav flex-column sub-menu"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/buses',
                }}},[_vm._v("All Buses")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/bus/create',
                }}},[_vm._v("Create Bus")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/buslayouts',
                }}},[_vm._v("All Bus Layout")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/buslayout/create',
                }}},[_vm._v("Add Bus Layout ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/bustypes',
                }}},[_vm._v("All Bus Type")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/bustype/create',
                }}},[_vm._v("Add Bus Type ")])],1)])])],1),_c('li',{staticClass:"nav-item"},[_c('span',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('manage-driver-dropdown'),expression:"'manage-driver-dropdown'"}],staticClass:"nav-link"},[_c('i',{staticClass:"menu-icon typcn typcn-group-outline"}),_c('span',{staticClass:"menu-title"},[_vm._v(" Manage Drivers ")]),_c('i',{staticClass:"menu-arrow"})]),_c('b-collapse',{attrs:{"id":"manage-driver-dropdown"}},[_c('ul',{staticClass:"nav flex-column sub-menu"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/drivers' }}},[_vm._v("All Drivers")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/driver/create',
                }}},[_vm._v("Create Driver")])],1)])])],1),_c('li',{staticClass:"nav-item"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('manage-routes'),expression:"'manage-routes'"}],staticClass:"nav-link"},[_c('i',{staticClass:"menu-icon typcn typcn-location-arrow-outline"}),_c('span',{staticClass:"menu-title"},[_vm._v("Fixed routes")]),_c('i',{staticClass:"menu-arrow"})]),_c('b-collapse',{attrs:{"id":"manage-routes"}},[_c('ul',{staticClass:"nav flex-column sub-menu"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/locations',
                }}},[_vm._v("All stops")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/location/create',
                }}},[_vm._v("Create stops")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/routes' }}},[_vm._v("All Routes")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/route/create',
                }}},[_vm._v("Create Route")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/bus-schedules',
                }}},[_vm._v("All bus Schedule")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/bus-schedules/create',
                }}},[_vm._v("Create bus schedule")])],1)])])],1),(_vm.getName.role == 'admin')?_c('li',{staticClass:"nav-item nav-category"},[_vm._v(" Administrations Settings ")]):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('manage-customers'),expression:"'manage-customers'"}],staticClass:"nav-link"},[_c('i',{staticClass:"menu-icon typcn typcn-group-outline"}),_c('span',{staticClass:"menu-title"},[_vm._v("Manage Customers")]),_c('i',{staticClass:"menu-arrow"})]),_c('b-collapse',{attrs:{"id":"manage-customers"}},[_c('ul',{staticClass:"nav flex-column sub-menu"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/customers' }}},[_vm._v("All Customers")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/referrals' }}},[_vm._v("Referrals")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/customers/wallet-recharge' }}},[_vm._v("Wallet Recharge")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/suggests' }}},[_vm._v("Suggestions")])],1)])])],1),_c('li',{staticClass:"nav-item"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('manage-offers'),expression:"'manage-offers'"}],staticClass:"nav-link"},[_c('i',{staticClass:"menu-icon typcn typcn-group-outline"}),_c('span',{staticClass:"menu-title"},[_vm._v("Manage Offers")]),_c('i',{staticClass:"menu-arrow"})]),_c('b-collapse',{attrs:{"id":"manage-offers"}},[_c('ul',{staticClass:"nav flex-column sub-menu"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/offers' }}},[_vm._v("All Offers")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/offer/create',
                }}},[_vm._v("Create Offer")])],1)])])],1),_c('li',{staticClass:"nav-item"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('manage-passes'),expression:"'manage-passes'"}],staticClass:"nav-link"},[_c('i',{staticClass:"menu-icon typcn typcn-group-outline"}),_c('span',{staticClass:"menu-title"},[_vm._v("Manage Passes")]),_c('i',{staticClass:"menu-arrow"})]),_c('b-collapse',{attrs:{"id":"manage-passes"}},[_c('ul',{staticClass:"nav flex-column sub-menu"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/passes' }}},[_vm._v("All Passes")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/pass/create',
                }}},[_vm._v("Create pass")])],1)])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
            path: '/help-and-support',
          }}},[_c('i',{staticClass:"menu-icon typcn typcn-group-outline"}),_c('span',{staticClass:"menu-title"},[_vm._v(" Help & Support")])])],1),_c('li',{staticClass:"nav-item"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('trips'),expression:"'trips'"}],staticClass:"nav-link"},[_c('i',{staticClass:"menu-icon typcn typcn-user-outline"}),_c('span',{staticClass:"menu-title"},[_vm._v("Manage Trips")]),_c('i',{staticClass:"menu-arrow"})]),_c('b-collapse',{attrs:{"id":"trips"}},[_c('ul',{staticClass:"nav flex-column sub-menu"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/trips/assign/create',
                  // name: 'tripassigncreate',
                }}},[_vm._v("Create")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/trips/assign/list',
                  //   name: 'tripassignlist',
                }}},[_vm._v("List")])],1)])])],1),_c('li',{staticClass:"nav-item"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('bookings'),expression:"'bookings'"}],staticClass:"nav-link"},[_c('i',{staticClass:"menu-icon typcn typcn-user-outline"}),_c('span',{staticClass:"menu-title"},[_vm._v("Manage Bookings")]),_c('i',{staticClass:"menu-arrow"})]),_c('b-collapse',{attrs:{"id":"bookings"}},[_c('ul',{staticClass:"nav flex-column sub-menu"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/bookings/scheduled',
                  // name: 'booking',
                  // params: { travel_status: 'scheduled' },
                }}},[_vm._v("Scheduled")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/bookings/onboarded',
                  // name: 'booking',
                  // params: { travel_status: 'onboarded' },
                }}},[_vm._v("Onboarded")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/bookings/completed',
                  // name: 'booking',
                  // params: { travel_status: 'completed' },
                }}},[_vm._v("Completed")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/bookings/cancelled',
                  // name: 'booking',
                  // params: { travel_status: 'cancelled' },
                }}},[_vm._v("Cancelled")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/bookings/expired',
                  // name: 'booking',
                  // params: { travel_status: 'expired' },
                }}},[_vm._v("Expired")])],1)])])],1),_c('li',{staticClass:"nav-item"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('payments'),expression:"'payments'"}],staticClass:"nav-link"},[_c('i',{staticClass:"menu-icon typcn typcn-user-outline"}),_c('span',{staticClass:"menu-title"},[_vm._v("Manage Payments")]),_c('i',{staticClass:"menu-arrow"})]),_c('b-collapse',{attrs:{"id":"payments"}},[_c('ul',{staticClass:"nav flex-column sub-menu"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/payments/completed',
                  // name: 'payment',
                  // params: { travel_status: 'refunded' },
                }}},[_vm._v("Completed")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/payments/refunded',
                  // name: 'payment',
                  // params: { travel_status: 'refunded' },
                }}},[_vm._v("Refunded")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/payments/processing',
                  // name: 'payment',
                  // params: { travel_status: 'refunded' },
                }}},[_vm._v("Processing")])],1)])])],1),(_vm.getUser.role === 'admin' || _vm.getUser.role === 'staff')?_c('li',{staticClass:"nav-item nav-category"},[_vm._v(" "+_vm._s(_vm.getUser.role.toUpperCase())+" Menu ")]):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('manage-notifications'),expression:"'manage-notifications'"}],staticClass:"nav-link"},[_c('i',{staticClass:"menu-icon typcn typcn-group-outline"}),_c('span',{staticClass:"menu-title"},[_vm._v("Manage Notifications")]),_c('i',{staticClass:"menu-arrow"})]),_c('b-collapse',{attrs:{"id":"manage-notifications"}},[_c('ul',{staticClass:"nav flex-column sub-menu"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/notifications',
                }}},[_vm._v("Lists")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/notifications/create',
                }}},[_vm._v("Create")])],1)])])],1),(_vm.getUser.role === 'admin')?_c('li',{staticClass:"nav-item"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('manage-users'),expression:"'manage-users'"}],staticClass:"nav-link"},[_c('i',{staticClass:"menu-icon typcn typcn-group-outline"}),_c('span',{staticClass:"menu-title"},[_vm._v("Manage Users")]),_c('i',{staticClass:"menu-arrow"})]),_c('b-collapse',{attrs:{"id":"manage-users"}},[_c('ul',{staticClass:"nav flex-column sub-menu"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/users' }}},[_vm._v("Manage Users")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/user/create',
                }}},[_vm._v("Create User")])],1)])])],1):_vm._e(),(_vm.getUser.role === 'admin')?_c('li',{staticClass:"nav-item"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('auth'),expression:"'auth'"}],staticClass:"nav-link"},[_c('i',{staticClass:"menu-icon typcn typcn-document-add"}),_c('span',{staticClass:"menu-title"},[_vm._v("Role and Permission")]),_c('i',{staticClass:"menu-arrow"})]),_c('b-collapse',{attrs:{"id":"auth"}},[_c('ul',{staticClass:"nav flex-column sub-menu"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  path: '/roles',
                }}},[_vm._v("Manage Role")])],1)])])],1):_vm._e(),(_vm.getUser.role == 'admin')?_c('li',{staticClass:"nav-item"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('administration-tools'),expression:"'administration-tools'"}],staticClass:"nav-link"},[_c('i',{staticClass:"menu-icon typcn typcn-document-add"}),_c('span',{staticClass:"menu-title"},[_vm._v("Administration Tools")]),_c('i',{staticClass:"menu-arrow"})]),_c('b-collapse',{attrs:{"id":"administration-tools"}},[_c('ul',{staticClass:"nav flex-column sub-menu"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  name: 'country',
                }}},[_c('i',{staticClass:"menu-icon typcn typcn-cog-outline"}),_c('span',{staticClass:"menu-title"},[_vm._v("Country")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  name: 'currency',
                }}},[_c('i',{staticClass:"menu-icon typcn typcn-cog-outline"}),_c('span',{staticClass:"menu-title"},[_vm._v("Currency")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  name: 'applicationsetting',
                }}},[_c('i',{staticClass:"menu-icon typcn typcn-cog-outline"}),_c('span',{staticClass:"menu-title"},[_vm._v("App Settings")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                  name: 'payment-settings',
                }}},[_c('i',{staticClass:"menu-icon typcn typcn-cog-outline"}),_c('span',{staticClass:"menu-title"},[_vm._v("Payment Settings")])])],1)])])],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }